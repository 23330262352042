define("discourse/plugins/stemaway-tiered-tagging/discourse/components/topics-color", ["exports", "@ember/component", "discourse/lib/ajax", "@ember/service", "jquery", "@ember/runloop"], function (_exports, _component, _ajax, _service, _jquery, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    tagName: "",
    siteSettings: (0, _service.inject)(),
    topicsService: (0, _service.inject)(),
    router: (0, _service.inject)(),
    init() {
      this._super(...arguments);
      this.router.on('routeDidChange', this, this.routeDidChange);
    },
    didReceiveAttrs() {
      this._super(...arguments);
      this.loadTopics();
    },
    willDestroyElement() {
      this._super(...arguments);
      this.router.off('routeDidChange', this, this.routeDidChange);
    },
    routeDidChange() {
      this.loadTopics();
      (0, _runloop.scheduleOnce)('afterRender', this, this.applyCssToTags);
    },
    loadTopics() {
      let categorySlug = this.siteSettings.categorySlug;
      let categoryId = this.siteSettings.categoryId;
      (0, _ajax.ajax)(`/c/${categorySlug}/${categoryId}.json`).then(result => {
        let topicsWithTags = result.topic_list.topics.filter(topic => topic.tags && topic.tags.length > 0);
        this.topicsService.set("topics", topicsWithTags);
        this.applyCssToTags();
      });
    },
    applyCssToTags() {
      let topics = this.topicsService.get('topics');
      if (topics) {
        topics.forEach(topic => {
          if (topic.subSkill2) {
            topic.subSkill2.forEach(tag => {
              (0, _jquery.default)(`body.category .discourse-tag.box[href*="${tag}"]`).css('background-color', this.siteSettings.skill_color);
              (0, _jquery.default)(`body.navigation-topics .discourse-tag.box[href*="${tag}"]`).css('background-color', this.siteSettings.skill_color);
            });
          }
          if (topic.subSkill) {
            topic.subSkill.forEach(tag => {
              (0, _jquery.default)(`body.category .discourse-tag.box[href*="${tag}"]`).css('background-color', this.siteSettings.subdomain_color);
              (0, _jquery.default)(`body.navigation-topics .discourse-tag.box[href*="${tag}"]`).css('background-color', this.siteSettings.subdomain_color);
            });
          }
          if (topic.skill) {
            topic.skill.forEach(tag => {
              (0, _jquery.default)(`body.category .discourse-tag.box[href*="${tag}"]`).css('background-color', this.siteSettings.domain_color);
              (0, _jquery.default)(`body.navigation-topics .discourse-tag.box[href*="${tag}"]`).css('background-color', this.siteSettings.domain_color);
            });
          }
          if (topic.pathway) {
            topic.pathway.forEach(tag => {
              (0, _jquery.default)(`body.category .discourse-tag.box[href*="${tag}"]`).css('background-color', this.siteSettings.pathway_color);
              (0, _jquery.default)(`body.navigation-topics .discourse-tag.box[href*="${tag}"]`).css('background-color', this.siteSettings.pathway_color);
            });
          }
        });
      }
    }
  });
});