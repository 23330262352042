define("discourse/plugins/stemaway-tiered-tagging/discourse/helpers/sort-skills", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.sortSkills = sortSkills;
  function sortSkills(params) {
    const [skills, pathwayOrder] = params;
    const order = pathwayOrder.toLowerCase().split(",");
    return [...skills].sort((a, b) => {
      const orderA = order.indexOf(a.name[0].toLowerCase());
      const orderB = order.indexOf(b.name[0].toLowerCase());
      if (orderA < orderB) return -1;
      if (orderA > orderB) return 1;
      return 0;
    });
  }
  var _default = _exports.default = (0, _helper.helper)(sortSkills);
});