define("discourse/plugins/stemaway-tiered-tagging/discourse/controllers/skills", ["exports", "@ember/controller", "@ember/object"], function (_exports, _controller, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class SkillsController extends _controller.default {
    selectTopic(topic) {
      this.set('currentTopic', topic);
    }
    static #_ = (() => dt7948.n(this.prototype, "selectTopic", [_object.action]))();
  }
  _exports.default = SkillsController;
});