define("discourse/plugins/stemaway-tiered-tagging/discourse/initializers/modify-topic-category", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'modify-topic-category',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.11.0', api => {
        api.modifyClass('component:topic-category', {
          pluginId: 'stemaway-tiered-tagging',
          didRender() {
            this._super(...arguments);
            this.addTagsToTopic2();
          },
          addTagsToTopic2() {
            let topic = this.topic;
            if (!topic) {
              return;
            }
            let element2 = this.element;
            let topic_category = element2.closest('.topic-category');
            const existingCustomTagsContainer = topic_category.querySelector('.custom-tags');
            if (existingCustomTagsContainer) {
              return;
            }
            let topic_header = element2.querySelector('.topic-header-extra');
            topic_category.style.flexDirection = 'column';
            topic_category.style.alignItems = 'flex-start';
            const existingTagsElement2 = element2.querySelector('.list-tags');
            if (existingTagsElement2) {
              existingTagsElement2.remove();
            }
            if (topic_header && topic_category) {
              const createTagElements2 = (label, values) => {
                let wrapperElem = document.createElement('div');
                wrapperElem.className = 'custom-tag-label';
                wrapperElem.textContent = `${label}: `;
                values.forEach(value => {
                  let tagElem = document.createElement('a');
                  tagElem.className = 'discourse-tag box';
                  tagElem.textContent = value;
                  tagElem.href = `/tag/${value}`;
                  tagElem.style.padding = "1px 5px";
                  tagElem.style.fontSize = "0.85em";
                  tagElem.style.marginRight = "4px";
                  tagElem.style.marginBottom = "2px";
                  wrapperElem.appendChild(tagElem);
                });
                return wrapperElem;
              };
              let customTagsContainer2 = document.createElement('div');
              customTagsContainer2.className = 'custom-tags';
              customTagsContainer2.style.alignItems = "left";
              const appendTags = (label, value) => {
                if (value) {
                  let values2 = String(value).split(',').map(v => v.trim()).filter(v => v.length > 0);
                  if (values2.length > 0) {
                    let tagElement2 = createTagElements2(label, values2);
                    customTagsContainer2.appendChild(tagElement2);
                  }
                }
              };
              appendTags('Domain', topic.pathway);
              appendTags('Subdomain', topic.skill);
              appendTags('Focus', topic.subSkill);
              topic_category.appendChild(customTagsContainer2);
            }
          }
        });
      });
    }
  };
});