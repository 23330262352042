define("discourse/plugins/stemaway-tiered-tagging/discourse/lib/skill-property-helpers", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error"], function (_exports, _ajax, _ajaxError) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.buildDropdown = buildDropdown;
  _exports.handlePrefillData = handlePrefillData;
  _exports.prepareData = prepareData;
  _exports.resetProperties = resetProperties;
  _exports.setPathwayProps = setPathwayProps;
  _exports.setSkillProps = setSkillProps;
  _exports.updateDependantDropdowns = updateDependantDropdowns;
  function buildDropdown(all, selected) {
    return all.filter(value => selected.includes(value.name)).map(value => value.skills || value.sub_skills || []).flat(1);
  }
  function updateDependantDropdowns(context, buffered, all, action) {
    if (!buffered) {
      return;
    }
    const updated = all.filter(v => buffered.includes(v.name)).map(v => v.name);
    return context.send(action, updated);
  }
  function resetProperties(model, component) {
    model.setProperties({
      pathway: null,
      skill: null,
      subSkill: null
    });
    return component.setProperties({
      showSkills: false,
      showSubSkills: false
    });
  }
  function setPathwayProps(context, all, selected) {
    const skills = buildDropdown(all, selected);
    return context.setProperties({
      showSkills: true,
      skills
    });
  }
  function setSkillProps(context, all, selected) {
    const subSkills = buildDropdown(all, selected);
    return context.setProperties({
      showSubSkills: true,
      subSkills
    });
  }
  function handlePrefillData(allData, context) {
    const model = context.model;
    let allSkills = [];
    if (model.pathway) {
      context.set("pathway", model.pathway);
      allSkills = buildDropdown(allData, model.pathway);
      setPathwayProps(context, allData, model.pathway);
    }
    if (model.skill) {
      setSkillProps(context, allSkills, model.skill);
    }
    if (model.subSkill && model.skill) {
      const allSubSkills = buildDropdown(allSkills, model.skill);
      context.setProperties({
        showSubSkills: true,
        subSkills: allSubSkills
      });
      context.set("buffered.subSkill", model.subSkill);
    }
    if (model.pathway === null && model.skill === null && model.subSkill === null && model.tags) {
      model.set("otherTags", model.tags);
    }
  }
  function prepareData(context) {
    return (0, _ajax.ajax)(`/skills.json`).then(result => {
      const allData = result.skills;
      context.set("pathways", allData);
      handlePrefillData(allData, context);
    }).catch(_ajaxError.popupAjaxError);
  }
});