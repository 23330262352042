define("discourse/plugins/stemaway-tiered-tagging/discourse/api-initializers/tiered-tagging", ["exports", "discourse-common/utils/decorators", "discourse/lib/api"], function (_exports, _decorators, _api) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _api.apiInitializer)("1.2.0", api => {
    const PLUGIN_ID = "stemaway-tiered-tagging";
    const FIELDS = [{
      name: "pathway",
      type: "json"
    }, {
      name: "skill",
      type: "json"
    }, {
      name: "subSkill",
      type: "json"
    }, {
      name: "subSkill2",
      type: "json"
    }, {
      name: "otherTags",
      type: "json"
    }];
    api.modifyClass("controller:composer", dt7948.p({
      pluginId: PLUGIN_ID,
      pathwayValidation() {
        // TODO ?
      }
    }, [["method", "pathwayValidation", [(0, _decorators.default)("model")]]]));
    api.modifyClass("model:composer", {
      pluginId: PLUGIN_ID,
      save(opts) {
        const pathway = this.pathway;
        const skill = this.skill;
        const subSkill = this.subSkill;
        const subSkill2 = this.subSkill2;
        const tags = this.tags;
        const addToTags = [];
        if (tags) {
          this.otherTags = tags;
          addToTags.push(...tags);
        }
        if (pathway) {
          addToTags.push(...pathway);
        }
        if (skill) {
          addToTags.push(...skill);
        }
        if (subSkill) {
          addToTags.push(...subSkill);
        }
        if (subSkill2) {
          addToTags.push(...subSkill2);
        }
        this.set("tags", addToTags);
        return this._super(...arguments);
      }
    });
    api.modifyClass("controller:topic", {
      pluginId: PLUGIN_ID,
      actions: {
        finishedEditingTopic() {
          const pathwayTags = this.buffered.get("pathway") || [];
          const skillTags = this.buffered.get("skill") || [];
          const subSkillTags = this.buffered.get("subSkill") || [];
          const subSkill2Tags = this.buffered.get("subSkill2") || [];
          const otherTags = this.buffered.get("otherTags") || [];
          const allTags = [...pathwayTags, ...skillTags, ...subSkillTags, ...subSkill2Tags, ...otherTags];
          this.buffered.set("tags", allTags);
          return this._super(...arguments);
        }
      }
    });
    FIELDS.forEach(field => {
      api.serializeOnCreate(field.name);
      api.serializeToDraft(field.name);
      api.serializeToTopic(field.name, `topic.${field.name}`);
    });
  });
});