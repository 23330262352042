define("discourse/plugins/stemaway-tiered-tagging/discourse/helpers/array-includes", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.arrayIncludes = arrayIncludes;
  _exports.default = void 0;
  function arrayIncludes(_ref) {
    let [haystack, needles] = _ref;
    if (!haystack || !needles) {
      return false;
    }
    return needles.some(needle => haystack.includes(needle));
  }
  var _default = _exports.default = (0, _helper.helper)(arrayIncludes);
});