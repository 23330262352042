define("discourse/plugins/stemaway-tiered-tagging/discourse/components/skill-card", ["exports", "@ember/component", "@ember/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend(dt7948.p({
    tagName: "div",
    classNames: ["skills-card-pathway"],
    highlight: false,
    didReceiveAttrs() {
      this._super(...arguments);
      this.set('forceRefresh', this.refreshCounter);
    },
    showSkills() {
      this.set("childSkills", !this.childSkills);
    },
    showSubSkills() {
      this.set("childSubSkills", !this.childSubSkills);
    }
  }, [["method", "showSkills", [_object.action]], ["method", "showSubSkills", [_object.action]]]));
});