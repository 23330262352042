define("discourse/plugins/stemaway-tiered-tagging/discourse/connectors/composer-fields/composer-skill-fields", ["exports", "discourse/plugins/stemaway-tiered-tagging/discourse/lib/skill-property-helpers"], function (_exports, _skillPropertyHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      return component.siteSettings.stemaway_tiered_tagging_enabled;
    },
    setupComponent(args, component) {
      (0, _skillPropertyHelpers.prepareData)(component);
    },
    actions: {
      updatePathwayTags(selected) {
        if (selected.length < 1) {
          (0, _skillPropertyHelpers.resetProperties)(this.model, this);
        }
        this.model.set("pathway", selected);
        (0, _skillPropertyHelpers.setPathwayProps)(this, this.pathways, selected);
      },
      updateSkillTags(selected) {
        this.model.set("skill", selected);
        (0, _skillPropertyHelpers.setSkillProps)(this, this.skills, selected);
      },
      updateSubSkillTags(selected) {
        this.model.set("subSkill", selected);
        (0, _skillPropertyHelpers.setSubSkillProps)(this, this.subSkills, selected);
      },
      updateSubSkill2Tags(selected) {
        this.model.set("subSkill2", selected);
        (0, _skillPropertyHelpers.setSubSkill2Props)(this, this.subSkills2, selected);
      }
    }
  };
});