define("discourse/plugins/stemaway-tiered-tagging/discourse/helpers/includes", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.includes = includes;
  function includes(_ref) {
    let [haystack, needle] = _ref;
    return haystack.includes(needle);
  }
  var _default = _exports.default = (0, _helper.helper)(includes);
});