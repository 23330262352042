define("discourse/plugins/stemaway-tiered-tagging/discourse/connectors/edit-topic/edit-topic-skill-fields", ["exports", "discourse/plugins/stemaway-tiered-tagging/discourse/lib/skill-property-helpers"], function (_exports, _skillPropertyHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    shouldRender(args, component) {
      return component.siteSettings.stemaway_tiered_tagging_enabled;
    },
    setupComponent(args, component) {
      (0, _skillPropertyHelpers.prepareData)(component);
    },
    actions: {
      updatePathwayTags(selected) {
        this.set("buffered.pathway", selected);
        if (selected.length < 1) {
          (0, _skillPropertyHelpers.resetProperties)(this.buffered, this);
        } else {
          (0, _skillPropertyHelpers.setPathwayProps)(this, this.pathways, selected);
          (0, _skillPropertyHelpers.updateDependantDropdowns)(this, this.get("buffered.skill"), this.get("skills"), "updateSkillTags");
        }
      },
      updateSkillTags(selected) {
        this.set("buffered.skill", selected);
        if (selected.length < 1) {
          this.buffered.set("subSkill", null);
          return this.set("showSubSkills", false);
        } else {
          (0, _skillPropertyHelpers.setSkillProps)(this, this.skills, selected);
          (0, _skillPropertyHelpers.updateDependantDropdowns)(this, this.get("buffered.subSkill"), this.get("subSkills"), "updateSubSkillTags");
        }
      },
      updateSubSkillTags(selected) {
        this.set("buffered.subSkill", selected);
      },
      updateOtherTags(selected) {
        this.set("buffered.otherTags", selected);
      }
    }
  };
});