define("discourse/plugins/stemaway-tiered-tagging/discourse/routes/skills", ["exports", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/routes/discourse", "I18n"], function (_exports, _ajax, _ajaxError, _discourse, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _discourse.default.extend({
    model() {
      return (0, _ajax.ajax)(`/skills.json`).catch(_ajaxError.popupAjaxError);
    },
    titleToken() {
      return _I18n.default.t("stemaway_tiered_tagging.skills_page.title");
    },
    setupController(controller, model) {
      const {
        skills
      } = model;
      controller.set("skills", skills);
    }
  });
});