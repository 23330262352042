define("discourse/plugins/stemaway-tiered-tagging/discourse/helpers/minus", ["exports", "@ember/component/helper"], function (_exports, _helper) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.minus = minus;
  function minus(params) {
    return params[0] - params[1];
  }
  var _default = _exports.default = (0, _helper.helper)(minus);
});