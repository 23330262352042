define("discourse/plugins/stemaway-tiered-tagging/discourse/initializers/modify-topic-list-body", ["exports", "discourse/lib/plugin-api"], function (_exports, _pluginApi) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'modify-topic-list-item',
    initialize() {
      (0, _pluginApi.withPluginApi)('0.11.0', api => {
        api.modifyClass('component:topic-list-item', {
          pluginId: 'stemaway-tiered-tagging',
          didRender() {
            this._super(...arguments);
            this.addTagsToTopic();
          },
          addTagsToTopic() {
            let topic = this.topic;
            if (!topic) {
              return;
            }
            let element = this.element;
            let linkBottomLine = element.querySelector('.link-bottom-line');
            const existingTagsElement = element.querySelector('.discourse-tags');
            if (linkBottomLine) {
              if (existingTagsElement) {
                existingTagsElement.remove();
              }
              linkBottomLine.style.marginBottom = "5px";
              const createTagElements = (label, values) => {
                let wrapperElem = document.createElement('div');
                wrapperElem.className = 'custom-tag-label';
                wrapperElem.textContent = `${label}: `;
                wrapperElem.style.fontSize = "0.85em";
                wrapperElem.style.marginBottom = "1px";
                values.forEach(value => {
                  let tagElem = document.createElement('a');
                  tagElem.className = 'discourse-tag box';
                  tagElem.textContent = value;
                  tagElem.href = `/tag/${value}`;
                  tagElem.style.padding = "1px 5px";
                  tagElem.style.fontSize = "0.85em";
                  tagElem.style.marginRight = "4px";
                  wrapperElem.appendChild(tagElem);
                });
                return [wrapperElem];
              };
              let customTagsContainer = document.createElement('div');
              customTagsContainer.className = 'custom-tags';
              const appendTags = (label, value) => {
                if (value) {
                  let stringValue = String(value);
                  let values = stringValue.split(',').map(v => v.trim());
                  let tagElements = createTagElements(label, values);
                  tagElements.forEach(elem => customTagsContainer.appendChild(elem));
                }
              };
              appendTags('Domain', topic.pathway);
              appendTags('Subdomain', topic.skill);
              appendTags('Focus', topic.subSkill);
              linkBottomLine.insertAdjacentElement('afterend', customTagsContainer);
            }
          }
        });
      });
    }
  };
});