define("discourse/plugins/stemaway-tiered-tagging/discourse/components/topics-in-category", ["exports", "@ember/component", "@ember/object", "discourse/lib/ajax", "@ember/service"], function (_exports, _component, _object, _ajax, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class DropdownComponent extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "siteSettings", [_service.inject]))();
    #siteSettings = (() => (dt7948.i(this, "siteSettings"), void 0))();
    topics = null;
    init() {
      super.init(...arguments);
      this.loadTopics();
    }
    loadTopics() {
      let categorySlug = this.siteSettings.categorySlug;
      let categoryId = this.siteSettings.categoryId;
      (0, _ajax.ajax)(`/c/${categorySlug}/${categoryId}.json`).then(result => {
        let topicsWithTags = result.topic_list.topics.filter(topic => topic.tags && topic.tags.length > 0);
        this.set("topics", topicsWithTags);
      });
    }
    static #_2 = (() => dt7948.n(this.prototype, "loadTopics", [_object.action]))();
    toggleDropdown(event) {
      event.stopPropagation();
      let dropdown = document.querySelector('.dropdown-content-topics');
      let backdrop = document.querySelector('.dropdown-backdrop');
      let dropdownTags = document.querySelector('.dropdown-tags');
      let button = document.querySelector('.dropbtn');
      let isDropdownVisible = dropdown.style.display === 'block';
      dropdown.style.display = isDropdownVisible ? 'none' : 'block';
      backdrop.style.display = isDropdownVisible ? 'none' : 'block';
      if (!isDropdownVisible) {
        document.querySelector('.dropdown-tags').style.display = 'none';
        button.classList.add('dropbtn-active');
      } else {
        button.classList.remove('dropbtn-active');
      }
      if (dropdown.style.display === 'none') {
        dropdownTags.style.display = 'none';
      }
      event.stopPropagation();
    }
    static #_3 = (() => dt7948.n(this.prototype, "toggleDropdown", [_object.action]))();
    showTags(topic, event) {
      const dropdownTags = document.querySelector('.dropdown-tags');
      dropdownTags.innerHTML = '';
      const baseUrlForTags = '/tags/';
      topic.tags.forEach(tag => {
        let tagLink = document.createElement('a');
        tagLink.setAttribute('href', `${baseUrlForTags}${encodeURIComponent(tag)}`);
        tagLink.setAttribute('target', '_blank');
        tagLink.textContent = tag;
        tagLink.className = 'discourse-tag box';
        dropdownTags.appendChild(tagLink);
      });
      let targetLink = event.target.closest('.dropdown-item-topics').querySelector('a');
      let linkRect = targetLink.getBoundingClientRect();
      dropdownTags.style.display = 'block';
      dropdownTags.style.left = `${linkRect.right + window.scrollX}px`;
      dropdownTags.style.top = `${linkRect.top + window.scrollY}px`;
    }
    static #_4 = (() => dt7948.n(this.prototype, "showTags", [_object.action]))();
    hideTags() {}
    static #_5 = (() => dt7948.n(this.prototype, "hideTags", [_object.action]))();
    didInsertElement() {
      super.didInsertElement();
      this.handleOutsideClick = event => {
        let dropdown = document.querySelector('.dropdown-content-topics');
        let backdrop = document.querySelector('.dropdown-backdrop');
        let dropdownTags = document.querySelector('.dropdown-tags');
        if (!this.element.contains(event.target)) {
          dropdown.style.display = 'none';
          backdrop.style.display = 'none';
          dropdownTags.style.display = 'none';
        }
      };
      document.addEventListener('click', this.handleOutsideClick);
    }
    willDestroyElement() {
      super.willDestroyElement();
      document.removeEventListener('click', this.handleOutsideClick);
    }
  }
  _exports.default = DropdownComponent;
});