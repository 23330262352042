define("discourse/plugins/stemaway-tiered-tagging/discourse/connectors/before-list-area/skill-tree", ["exports", "@ember/component", "@ember/service", "discourse/lib/ajax", "@ember/template", "@ember/object"], function (_exports, _component, _service, _ajax, _template, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    router: (0, _service.inject)(),
    tagName: '',
    currentTag: null,
    skillTrees: null,
    error: null,
    domainColorStyle: (0, _object.computed)('siteSettings.domain_color', function () {
      return (0, _template.htmlSafe)(`background-color: ${this.siteSettings.domain_color};`);
    }),
    subdomainColorStyle: (0, _object.computed)('siteSettings.subdomain_color', function () {
      return (0, _template.htmlSafe)(`background-color: ${this.siteSettings.subdomain_color};`);
    }),
    focusColorStyle: (0, _object.computed)('siteSettings.focus_color', function () {
      return (0, _template.htmlSafe)(`background-color: ${this.siteSettings.focus_color};`);
    }),
    init() {
      this._super(...arguments);
      this.updateSkillTree();
      this.router.on('routeDidChange', this, this.updateSkillTree);
    },
    willDestroy() {
      this._super(...arguments);
      this.router.off('routeDidChange', this, this.updateSkillTree);
    },
    didReceiveAttrs() {
      this._super(...arguments);
      this.updateSkillTree();
    },
    updateSkillTree() {
      const currentRouteName = this.router.currentRouteName;
      if (currentRouteName === 'tag.show') {
        const tag = this.router.currentRoute.params.tag_id;
        if (tag !== this.currentTag) {
          this.set('currentTag', tag);
          this.fetchSkillTree(tag);
        }
      }
    },
    fetchSkillTree(tag) {
      (0, _ajax.ajax)(`/stemaway-tiered-tagging/${encodeURIComponent(tag)}`).then(data => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        if (data && data.hierarchies) {
          this.set('skillTrees', data.hierarchies);
          this.set('skillTrees', this.skillTrees.map(skillTree => {
            return skillTree.map((skill, index) => {
              return {
                name: skill,
                color: this.getColorForDepth(index)
              };
            });
          }));
        } else {
          this.set('error', "Failed to parse skill tree.");
        }
      }).catch(error => {
        if (this.isDestroyed || this.isDestroying) {
          return;
        }
        console.error("Error fetching skill tree:", error);
        this.set('error', "Failed to fetch skill tree.");
      });
    },
    getColorForDepth(depth) {
      let color;
      switch (depth) {
        case 0:
          color = this.siteSettings.domain_color;
          break;
        case 1:
          color = this.siteSettings.subdomain_color;
          break;
        case 2:
          color = this.siteSettings.focus_color;
          break;
        default:
          color = null;
      }
      return color ? (0, _template.htmlSafe)(`background-color: ${color};`) : null;
    }
  });
});