define("discourse/plugins/stemaway-tiered-tagging/discourse/stemaway-tiered-tagging-route-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _default;
  function _default() {
    this.route("skills", {
      path: "/skills"
    });
  }
});